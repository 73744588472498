import { BaseHook, QueryKeys } from '../../shared';
import { IApplication, ISaveApplication } from '../models';
import { applicationClient } from '../clients';

class ApplicationHook extends BaseHook<IApplication, ISaveApplication> {
    public constructor() {
        super(applicationClient, QueryKeys.APPLICATIONS, QueryKeys.APPLICATION);
    }
}
export const applicationHook = new ApplicationHook();
