import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { useShowDrawer } from '../../../shared';
import { Account } from '../account/account.component';
import { AppTitle } from '../app-title/app-title.component';
import { NavBar } from '../nav-bar/nav-bar.component';

export function Header() {
    const { showDrawer, setShowDrawer } = useShowDrawer();

    return (
        <>
            <Box component="div" sx={{ height: 55 }} />
            <AppBar
                color="default"
                elevation={0}
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    borderBottom: (theme) => `solid 1px ${theme.palette.grey['300']}`,
                    backgroundColor: 'white',
                    color: 'primary.main',
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => setShowDrawer(!showDrawer)}
                        sx={{ mr: 2, display: { xl: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <AppTitle />
                    <NavBar />
                    <Account />
                </Toolbar>
            </AppBar>
        </>
    );
}
