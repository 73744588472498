import { Grid } from '@mui/material';
import { Children, ComponentProps, FC, ReactNode } from 'react';

interface Props extends ComponentProps<typeof Grid> {
    items?: ReactNode[];
    vertical?: boolean;
}

export const FormGrid: FC<Props> = ({ items, vertical, children, ...gridProps }) => {
    return (
        <Grid container direction={vertical ? 'column' : 'row'} spacing={2} {...gridProps}>
            {items?.map((item, key) => (
                <Grid item key={key}>
                    {item}
                </Grid>
            ))}
            {Children.map(children, (child, key) =>
                child ? (
                    <Grid item key={key}>
                        {child}
                    </Grid>
                ) : (
                    <></>
                ),
            )}
        </Grid>
    );
};
