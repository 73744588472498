import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Page, RemoveModal, TableComponent, useQueryParams } from '../../../shared';
import { providerHook } from '../../hooks';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteOutline } from '@mui/icons-material';

export const ProvidersPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'key', sort: 'asc' }],
    });
    const { data: providers, isLoading } = providerHook.useList({
        page: page,
        limit: pageSize,
        sort: sort[0].field
            .split(', ')
            .map((sortBy) => `${sortBy}:${sort[0].sort?.toUpperCase()}`)
            .join(','),
    });

    const { mutateAsync: deleteProviders } = providerHook.useDelete();

    const columns: GridColDef[] = [
        {
            field: 'key',
            headerName: t('providerKey'),
            minWidth: 50,
            flex: 1,
        },
        {
            field: 'name',
            headerName: t('providerName'),
            minWidth: 50,
            flex: 1,
        },
        {
            field: 'type',
            headerName: t('providerType'),
            minWidth: 50,
            flex: 1,
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton component={Link} to={`/providers/${params.row.id}`} size="large">
                        <EditIcon />
                    </IconButton>
                    <RemoveModal
                        handleDelete={() => deleteProviders(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large" data-testid={`delete-${params.id}`}>
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('providerDeleteWarningTitle')}
                        text={t('providerDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('providers')}
            actions={[
                <Button component={Link} to="/providers/new" color="primary" variant="contained">
                    {t('newProvider')}
                </Button>,
            ]}
        >
            <TableComponent
                rows={providers?.items || []}
                columns={columns}
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={providers?.meta?.totalItems || 0}
                onPageChange={(newPage) => setPage(newPage + 1)}
                loading={isLoading}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={[{ field: 'key', sort: 'asc' }]}
                onRowClick={(row) => navigate(`/providers/${row.id}`)}
            />
        </Page>
    );
};
