import { createTheme, CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { enUS, nlNL } from '@mui/material/locale';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const globalStyles = (
    <GlobalStyles
        styles={{
            '.MuiDataGrid-root': { background: '#fff' },
            '.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': { display: 'none' },
        }}
    />
);

type Props = {
    children?: ReactNode;
};

export const CarityTheme: FC<Props> = ({ children }) => {
    const { i18n } = useTranslation();
    const theme = createTheme(
        {
            palette: {
                primary: {
                    main: window.carity.environment.theme.color,
                },
                secondary: {
                    main: window.carity.environment.theme.color,
                },
                background: {
                    default: '#FAFAFA',
                    paper: '#FFF',
                },
            },
            components: {
                MuiToolbar: {
                    styleOverrides: {
                        root: { minHeight: 55, '@media (min-width: 0px)': { minHeight: 55 } },
                    },
                },

                MuiFormControl: {
                    defaultProps: {
                        fullWidth: true,
                    },
                },
                MuiTextField: {
                    defaultProps: {
                        InputLabelProps: {
                            shrink: true,
                        },
                        fullWidth: true,
                    },
                },
                MuiInputLabel: {
                    defaultProps: {
                        shrink: true,
                    },
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            background: '#FAFAFA',
                            justifyContent: 'flex-start',
                            padding: '16px 24px',
                        },
                    },
                },
                MuiCardHeader: {
                    styleOverrides: {
                        root: {
                            paddingBottom: 0,
                        },
                        title: {
                            fontSize: 18,
                            fontWeight: 'bolder',
                        },
                    },
                },
                MuiCardContent: {
                    styleOverrides: {
                        root: {
                            '&:last-child': {
                                paddingBottom: '16px',
                            },
                        },
                    },
                },
            },
        },
        i18n.language === 'nl' ? nlNL : enUS,
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {globalStyles}
            {children}
        </ThemeProvider>
    );
};
