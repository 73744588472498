import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = ComponentProps<typeof Checkbox> & {
    name: string;
    label: string;
};

export const ControlledCheckbox: FC<Props> = ({ name, label, ...props }) => {
    const { t } = useTranslation();

    return (
        <Controller
            name={name}
            defaultValue={false}
            render={({ field: { name, onChange, value }, fieldState }) => (
                <FormControl error={!!fieldState.error}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...props}
                                checked={value}
                                onChange={onChange}
                                name={name}
                                data-testid={`${name}-checkbox`}
                            />
                        }
                        label={label}
                    />
                    {fieldState.error && (
                        <FormHelperText id={`${name}-field-helper-text`}>
                            {fieldState.error.message || t('shared.form.fallbackError')}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};
