import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, MenuItem } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { mixed, object, string } from 'yup';
import {
    ControlledInput,
    ControlledSelect,
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    IParams,
    IProviderForm,
    isAxiosError,
    Page,
    ProviderType,
    StatusCode,
} from '../../../shared';
import { JsonEditor } from '../../components';
import { providerHook } from '../../hooks';
import { providerFromFormMapper, providerToFormMapper } from '../../mappers';

export const ProviderEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: provider, isLoading } = providerHook.useItem(id);
    const { mutateAsync: saveProvider, isLoading: isSaving } = providerHook.useSave();
    const defaultConfig = {
        redirectUri: '',
        clientSecret: '',
        clientId: '',
    };

    const schema = object().shape({
        key: string()
            .matches(/^[a-z\-]*$/, t('providerKeyMatches'))
            .required(t('requiredField')),
        name: string(),
        type: mixed().oneOf(Object.values(ProviderType)).required(t('requiredField')),
        config: string()
            .required(t('requiredField'))
            .test((data, ctx) => {
                try {
                    JSON.parse(data);
                    return true;
                } catch (e) {
                    return ctx.createError({ message: t('invalidJSONFormat') });
                }
            }),
    });

    const form = useForm<IProviderForm>({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (provider) {
            form.reset(providerToFormMapper(provider));
        } else {
            form.reset({ config: JSON.stringify(defaultConfig) });
        }
    }, [provider, form.reset]);

    const onSubmit = async (item: IProviderForm) => {
        try {
            await saveProvider({ item: providerFromFormMapper(item), id });
            navigate('/providers');
        } catch (e) {
            if (isAxiosError(e)) {
                if ((e as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                    form.setError('key', {
                        type: 'custom',
                        message: t('providerNameUniqueError'),
                    });
                }
            } else {
                throw e;
            }
        }
    };

    const actions = [
        <Button onClick={() => navigate('/providers')} color="secondary">
            {t('cancel')}
        </Button>,
        <Button type="submit" variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
            {t('save')}
        </Button>,
    ];

    return (
        <Page
            title={id ? `${provider?.key || ''}` : t('newProvider')}
            actions={actions}
            loading={isLoading || isSaving}
        >
            <FormProvider {...form}>
                <FormCard actions={actions.slice().reverse()} handleSubmit={form.handleSubmit(onSubmit)}>
                    <FormCardSectionComponent title={t('data')}>
                        <FormGrid
                            direction="column"
                            items={[
                                <ControlledInput name="key" label={t('providerKey')} required />,
                                <ControlledInput name="name" label={t('providerName')} />,
                                <ControlledSelect name="type" label={t('providerType')} required>
                                    {Object.values(ProviderType).map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </ControlledSelect>,
                                <Controller
                                    name="config"
                                    render={({ field: { onChange }, fieldState: { error } }) => (
                                        <>
                                            <JsonEditor
                                                onChange={onChange}
                                                initialValue={provider?.config || defaultConfig}
                                            />
                                            {error && (
                                                <FormHelperText id={`config-field-helper-text`} error>
                                                    {error.message || t('shared.form.fallbackError')}
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                />,
                            ]}
                        />
                    </FormCardSectionComponent>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
