import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AuthListQueryParam, ListModelItems, QueryKeys } from '../../shared';
import { contextsClient } from '../clients';
import { IContext } from '../models';

export function useGetContextsList(params: AuthListQueryParam) {
    const { t } = useTranslation();

    return useQuery<ListModelItems<IContext>, AxiosError>([QueryKeys.CONTEXTS, params], () =>
        contextsClient.getList(params, t('getContextsError')),
    );
}
