import { AxiosError } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { BaseClient } from '../clients';
import { AuthListQueryParam, IBaseModel } from '../models';
import { ReadOnlyHook } from './read-only.hook';

export abstract class BaseHook<
    ResponseModel extends IBaseModel,
    Data extends Record<string, any>,
    QueryModel extends AuthListQueryParam = AuthListQueryParam,
> extends ReadOnlyHook<ResponseModel, QueryModel> {
    protected constructor(
        protected client: BaseClient<ResponseModel, Data>,
        protected listKey: string,
        protected itemKey: string,
    ) {
        super(client, listKey, itemKey);
    }

    public useSave(): UseMutationResult<ResponseModel, AxiosError, { item: Data; id?: string }> {
        const queryClient = useQueryClient();
        return useMutation(({ item, id }) => (id ? this.client.updateOne(id, item) : this.client.createOne(item)), {
            onSuccess: async ({ id }) => {
                await queryClient.invalidateQueries([this.listKey]);
                await queryClient.invalidateQueries([this.itemKey, id]);
            },
        });
    }

    public useDelete(): UseMutationResult<ResponseModel, AxiosError, string> {
        const queryClient = useQueryClient();
        return useMutation((id) => this.client.deleteOne(id), {
            onSuccess: async () => {
                await queryClient.invalidateQueries([this.listKey]);
            },
        });
    }
}
