import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, MenuItem, Stack } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { array, object, string } from 'yup';
import { ControlledSelect, DialogTitleWithClose, rolesHook } from '../../../shared';
import { useGetContextsList, usersHook } from '../../hooks';
import { IUserRoles, IUserRolesForm } from '../../models';

interface Props {
    userId: string;
    allUserRoles: IUserRoles[];
    open: boolean;
    handleClose: () => void;
    userRolesOrganisation?: IUserRoles;
}

export const UserRolesModal: FC<Props> = ({ open, handleClose, userId, allUserRoles, userRolesOrganisation }) => {
    const { t } = useTranslation();

    const { data: contexts } = useGetContextsList({ page: 1, limit: 1000 });
    const { data: roles } = rolesHook.useList({ page: 1, limit: 100 });

    const { mutateAsync: saveUserRoles } = usersHook.useSaveUserRoles();

    const schema = object().shape({
        contextId: string(),
        roles: array()
            .of(yup.string())
            .test('len', t('minOneValueSelected'), (val) => !!val && val.length > 0),
    });

    const form = useForm<IUserRolesForm>({
        resolver: yupResolver(schema),
        mode: 'all',
    });

    useEffect(() => {
        if (userRolesOrganisation) {
            form.reset({
                id: userRolesOrganisation.id,
                contextId: userRolesOrganisation?.context?.id,
                roles: userRolesOrganisation.roles.map((role) => role.id),
            });
        } else {
            form.reset({ roles: [] });
        }
    }, [form.reset, userRolesOrganisation, open]);

    const onSubmit = async (data: IUserRolesForm) => {
        handleClose();
        await saveUserRoles({
            id: data.id,
            subject: { user: { id: userId } },
            context: { id: data.contextId },
            roles: data.roles,
        });
    };

    const filteredContexts = useMemo(() => {
        return (
            contexts?.items.filter(
                (c) =>
                    userRolesOrganisation?.context?.id === c.id ||
                    !allUserRoles?.some((userRole) => userRole.context?.id === c.id),
            ) || []
        );
    }, [contexts, allUserRoles, userRolesOrganisation]);

    return (
        <FormProvider {...form}>
            <Dialog open={open}>
                <DialogTitleWithClose onClose={handleClose}>{t('addUserRoles')}</DialogTitleWithClose>
                <DialogContent>
                    <Box sx={{ pt: 1, minWidth: 500 }}>
                        <Stack spacing={2}>
                            <ControlledSelect name="contextId" label={t('context')}>
                                {filteredContexts.map((c) => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.id}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                            <ControlledSelect
                                name="roles"
                                label={t('roles')}
                                items={roles?.items.map((item) => item.id) || []}
                                required
                                multiple
                            >
                                {roles?.items?.map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.key}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={form.handleSubmit((data) => onSubmit(data))}
                        id="submit-user-roles"
                    >
                        {t('save')}
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
};
