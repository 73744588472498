export enum PermissionKeys {
    USERS_READ = 'users-read',
    USERS_WRITE = 'users-write',
    APPLICATIONS_READ = 'applications-read',
    APPLICATIONS_WRITE = 'applications-write',
    PROVIDERS_READ = 'providers-read',
    PROVIDERS_WRITE = 'providers-write',
    ROLES_READ = 'roles-read',
    ROLES_WRITE = 'roles-write',
}
