import { AxiosResponse } from 'axios';
import { AuthListQueryParam, ListModelItems } from '../../shared';
import { authApi } from '../../shared/clients';
import { ISaveUser, IUser, IUserRoles, IUserRolesSave } from '../models';

class UsersClient {
    public async getUsers(params: AuthListQueryParam, onGetErrorMessage?: string): Promise<ListModelItems<IUser>> {
        const { data } = await authApi.get(`/v1/users`, { params, errorMessage: onGetErrorMessage });

        return data;
    }

    public async getUser(id: string, onGetErrorMessage?: string): Promise<IUser> {
        const { data } = await authApi.get(`/v1/users/${id}`, {
            errorMessage: onGetErrorMessage,
        });
        return data;
    }

    public async saveUser(
        { id, ...user }: ISaveUser,
        messages: {
            onUpdateSuccessMessage?: string;
            onUpdateErrorMessage?: string;
            onCreateSuccessMessage?: string;
            onCreateErrorMessage?: string;
        },
    ): Promise<IUser> {
        let result: AxiosResponse<IUser>;

        if (id) {
            result = await authApi.put(`/v1/users/${id}`, user, {
                successMessage: messages.onUpdateSuccessMessage,
                errorMessage: messages.onUpdateErrorMessage,
            });
        } else {
            result = await authApi.post(`/v1/users`, user, {
                successMessage: messages.onCreateSuccessMessage,
                errorMessage: messages.onCreateErrorMessage,
            });
        }

        return result.data;
    }

    public async deleteUser(id: string, onDeleteSuccessMessage?: string, onDeleteErrorMessage?: string): Promise<void> {
        await authApi.delete(`/v1/users/${id}`, {
            successMessage: onDeleteSuccessMessage,
            errorMessage: onDeleteErrorMessage,
        });
    }

    public async getUserRoles(
        userId: string,
        params: AuthListQueryParam,
        onGetErrorMessage?: string,
    ): Promise<ListModelItems<IUserRoles>> {
        const { data } = await authApi.get(`/v1/acls`, {
            params: {
                limit: 1000,
                ...params,
                'subject.user.id': userId,
            },
            errorMessage: onGetErrorMessage,
        });

        return data;
    }

    public async saveUserRoles(
        { id, ...userRoles }: IUserRolesSave,
        onSuccessMessage?: string,
        onErrorMessage?: string,
    ): Promise<IUserRolesSave> {
        let result;
        if (!id) {
            result = await authApi.post(`/v1/acls`, userRoles, {
                successMessage: onSuccessMessage,
                errorMessage: onErrorMessage,
            });
        } else {
            result = await authApi.put(`/v1/acls/${id}`, userRoles, {
                successMessage: onSuccessMessage,
                errorMessage: onErrorMessage,
            });
        }

        return result.data;
    }

    public async deleteUserRoles(
        id: string,
        onDeleteSuccessMessage?: string,
        onDeleteErrorMessage?: string,
    ): Promise<void> {
        await authApi.delete(`/v1/acls/${id}`, {
            successMessage: onDeleteSuccessMessage,
            errorMessage: onDeleteErrorMessage,
        });
    }
}

export const usersClient = new UsersClient();
