import { DataGrid, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { ComponentProps, Dispatch } from 'react';

interface Props extends ComponentProps<typeof DataGrid> {
    sortModel?: GridSortItem[];
    onSortModelChange?: Dispatch<GridSortModel>;
    defaultSortModel?: GridSortItem[];
}

export function TableComponent({ onSortModelChange, defaultSortModel, ...props }: Props) {
    const handleSortModelChange = (newModel: GridSortModel) => {
        if (onSortModelChange && defaultSortModel && props.sortModel) {
            if (!newModel[0]) {
                onSortModelChange(defaultSortModel);
            } else if (newModel[0].sort !== props.sortModel[0].sort || newModel[0].field !== props.sortModel[0].field) {
                onSortModelChange(newModel);
            }
        }
    };

    return (
        <DataGrid
            autoHeight
            rowsPerPageOptions={props.rowsPerPageOptions ? props.rowsPerPageOptions : []}
            pagination
            paginationMode="server"
            sortingMode="server"
            sortingOrder={['asc', 'desc']}
            onSortModelChange={handleSortModelChange}
            disableColumnFilter
            disableColumnMenu
            {...props}
        />
    );
}
