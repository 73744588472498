import { FC, useEffect, useState } from 'react';
import JSONEditor, { JSONEditorMode } from 'jsoneditor/index';
import 'jsoneditor/dist/jsoneditor.css';
import { Box } from '@mui/material';

interface Props {
    onChange: (data: string) => void;
    initialValue: any;
}

export const JsonEditor: FC<Props> = ({ onChange, initialValue }) => {
    const [jsoneditor, setJsoneditor] = useState<JSONEditor>();
    const [container, setContainer] = useState<HTMLElement>();

    useEffect(() => {
        const options = {
            mode: 'text' as JSONEditorMode,
            onChangeText: onChange,
        };

        if (container) {
            setJsoneditor(new JSONEditor(container, options));
            jsoneditor?.set(initialValue);

            return jsoneditor?.destroy();
        }
    }, [container]);

    useEffect(() => {
        if (jsoneditor) {
            jsoneditor.update(initialValue);
        }
    }, [initialValue, jsoneditor]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                '& .jsoneditor': {
                    borderColor: window.carity.environment.theme.color,
                    '& .jsoneditor-menu': {
                        backgroundColor: window.carity.environment.theme.color,
                        border: 'none',
                    },
                },
            }}
            ref={(elem: HTMLDivElement) => {
                if (elem) {
                    setContainer(elem);
                }
            }}
        />
    );
};
