import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { IUserResponse } from '../../models';
import { QueryKeys } from '../../../shared';

async function getCurrentUser(onGetErrorMessage?: string): Promise<IUserResponse> {
    const { data } = await axios.get('/auth/user', { errorMessage: onGetErrorMessage });
    return data;
}

export const useGetCurrentUser = () => {
    const { t } = useTranslation();
    return useQuery<IUserResponse, AxiosError>([QueryKeys.AUTH_USER], () => getCurrentUser(t('getUserError')), {});
};
