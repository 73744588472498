import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadOnlyClient } from '../clients';
import { AuthListQueryParam, IBaseModel, ListModelItems } from '../models';

export abstract class ReadOnlyHook<
    ResponseModel extends IBaseModel,
    QueryModel extends AuthListQueryParam = AuthListQueryParam,
> {
    protected constructor(
        protected client: ReadOnlyClient<ResponseModel>,
        protected listKey: string,
        protected itemKey: string,
    ) {}

    public useList(
        query: QueryModel,
        options?: UseQueryOptions<ListModelItems<ResponseModel>, AxiosError>,
    ): UseQueryResult<ListModelItems<ResponseModel>, AxiosError> {
        return useQuery<ListModelItems<ResponseModel>, AxiosError>(
            [this.listKey, query],
            () => this.client.getList(query),
            options,
        );
    }

    public useItem(
        id?: string,
        options: UseQueryOptions<ResponseModel, AxiosError> = {},
    ): UseQueryResult<ResponseModel, AxiosError> {
        return useQuery<ResponseModel, AxiosError>([this.itemKey, id], () => this.client.getOne(id), {
            enabled: !!id,
            ...options,
        });
    }
}
