import { CalendarTodayTwoTone, DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RemoveModal, TableComponent, Widget } from '../../../shared';
import { usersHook } from '../../hooks';
import { IUserRoles, Role } from '../../models';
import { UserRolesModal } from '../user-roles-modal/user-roles-modal.component';

type Props = {
    userId: string;
};

export const UserRoles: FC<Props> = ({ userId }) => {
    const { t } = useTranslation();
    const [saveUserRolesModal, setSaveUserRolesModal] = useState<{ show: boolean; role?: IUserRoles }>({
        show: false,
        role: undefined,
    });

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    const { data: userRoles, isLoading } = usersHook.useGetUserRoles(userId, {
        page,
        limit,
    });
    const { mutateAsync: deleteUserRoles } = usersHook.useDeleteUserRoles();

    const columns: GridColDef[] = [
        {
            field: 'context',
            headerName: t('context'),
            flex: 1,
            sortable: false,
            valueFormatter: ({ value }) => value?.id,
        },
        {
            field: 'roles',
            headerName: t('roles'),
            flex: 1,
            sortable: false,
            valueFormatter: ({ value }) => (value as Role[])?.map(({ key }) => key).join(', '),
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton
                        size="large"
                        onClick={() => setSaveUserRolesModal({ show: true, role: params.row })}
                        data-testid={`edit-${params.row.id}`}
                    >
                        <EditIcon />
                    </IconButton>
                    <RemoveModal
                        handleDelete={() => deleteUserRoles(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large" data-testid={`delete-${params.id}`}>
                                <DeleteOutline />
                            </IconButton>
                        }
                        title={t('userRoleDeleteWarningTitle')}
                        text={t('userRoleDeleteWarningText')}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <Widget
                icon={<CalendarTodayTwoTone color="primary" />}
                title={t('roles')}
                hasTable
                actions={
                    <Button
                        variant="outlined"
                        color="primary"
                        data-testid="add-user-roles"
                        onClick={() => setSaveUserRolesModal({ show: true, role: undefined })}
                    >
                        + {t('addUserRoles')}
                    </Button>
                }
            >
                <TableComponent
                    rowsPerPageOptions={[5, 10, 20]}
                    rows={userRoles?.items || []}
                    columns={columns}
                    pageSize={limit}
                    onPageSizeChange={setLimit}
                    rowCount={userRoles?.meta?.totalItems || 0}
                    onPageChange={(newPage: number) => setPage(newPage + 1)}
                    loading={isLoading}
                />
            </Widget>
            <UserRolesModal
                userId={userId}
                allUserRoles={userRoles?.items || []}
                open={saveUserRolesModal.show}
                handleClose={() => setSaveUserRolesModal({ show: false, role: undefined })}
                userRolesOrganisation={saveUserRolesModal.role}
            />
        </>
    );
};
