import authTranslationsNL from './auth.nl';
import commonTranslationsNL from './common.nl';
import userTranslationsNL from './user.nl';
import roleTranslationsNL from './role.nl';
import providerTranslationsNL from './provider.nl';
import applicationTranslationsNL from './application.nl';

export const translationsNL = {
    translation: {
        ...authTranslationsNL,
        ...commonTranslationsNL,
        ...userTranslationsNL,
        ...roleTranslationsNL,
        ...providerTranslationsNL,
        ...applicationTranslationsNL,
    },
};
