export default {
    addBtn: 'Voeg toe',
    addressLabel: 'Adres',
    cancel: 'Annuleer',
    cityLabel: 'Stad',
    confirm: 'bevestig',
    comments: 'Commentaar',
    contactInformation: 'Contactinformatie',
    contactInformationRequired: 'Contactinformatie is verplicht.',
    contactLabel: 'Contact',
    contactsAdd: 'Voeg contact toe',
    countryLabel: 'Land',
    'creation-successful': 'Succesvol aangemaakt',
    data: 'Gegevens',
    date: 'Datum',
    delete: 'Verwijder',
    edit: 'Pas aan',
    goBack: 'Ga terug naar de vorige pagina',
    name: 'Naam',
    nameRequired: 'Naam is een verplicht veld.',
    noInfo: 'Geen gegevens beschikbaar.',
    numberLabel: 'Nummer',
    ok: 'ok',
    context: 'Context',
    pageNotFoundErr: 'Deze pagina kon niet worden gevonden.',
    postalCodeLabel: 'Postcode',
    save: 'Opslaan',
    search: 'Zoek',
    'something-went-wrong': 'Er ging iets mis',
    streetLabel: 'Straat',
    'update-successful': 'Succesvol aangepast',
    logOut: 'Uitloggen',
    forbiddenTitle: 'Verboden toegang',
    forbiddenText: 'Je hebt onvoldoende rechten voor het uitvoeren van de laatste actie.',
    notFoundTitle: 'Pagina niet gevonden',
    notFoundText: 'Oeps... Deze pagina bestaat niet.',
    backButton: 'Ga terug naar vorige pagina',
    unknown: 'Onbekend',
    createdDate: 'Aanmaakdatum',
    amountMustBeGreaterThan0: 'Aantal moet groter dan 0 zijn',
    getContextsError: 'Er is een probleem opgetreden bij het ophalen van de contexts.',
    requiredField: 'Dit veld is verplicht',
    tryAgainLater:
        'Probeer later nog eens, indien deze foutmelding zich opnieuw voordoet: contacteer de verantwoordelijke.',
};
