import { FormControl, FormHelperText, Input, InputLabel, OutlinedInput, Select } from '@mui/material';
import { ComponentProps, FC, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = ComponentProps<typeof Select> & {
    name: string;
    label?: string;
    minWidth?: number;
    maxWidth?: number;
    items?: string[];
    externalOnBlur?: (fieldName: string) => void;
};

export const ControlledSelect: FC<Props> = ({
    name,
    required,
    label,
    children,
    minWidth = 195,
    maxWidth,
    items = [],
    externalOnBlur,
    ...selectProps
}) => {
    const theme = window.carity.environment.theme;
    const { t } = useTranslation();

    const handleOnChangeMultipleSelect = (e: any) => {
        if (e.target.value.includes('all')) {
            if (e.target.value.length === items.length + 1) {
                e.target.value = [];
            } else {
                e.target.value = items;
            }
        }
        return e;
    };

    const handleBlur = useCallback(
        (internalBlur: any) => {
            internalBlur();
            externalOnBlur?.(name);
        },
        [externalOnBlur],
    );

    return (
        <Controller
            name={name}
            defaultValue={selectProps.multiple ? [] : ''}
            render={({ field: { onChange, value, onBlur: internalOnBlur }, fieldState }) => (
                <FormControl sx={{ minWidth }} error={!!fieldState.error} disabled={selectProps.disabled}>
                    {label && (
                        <InputLabel id={name} required={required}>
                            {label}
                        </InputLabel>
                    )}
                    <Select
                        sx={{ minWidth, maxWidth }}
                        onChange={!selectProps.multiple ? onChange : (e) => onChange(handleOnChangeMultipleSelect(e))}
                        onBlur={() => handleBlur(internalOnBlur)}
                        value={value}
                        id={`${name}-select`}
                        required={required}
                        input={theme.name === 'antwerp' ? <Input /> : <OutlinedInput notched label={label} />}
                        label={label}
                        {...selectProps}
                    >
                        {children}
                    </Select>
                    {fieldState.error && (
                        <FormHelperText id={`${name}-field-helper-text`}>
                            {fieldState.error.message || t('shared.form.fallbackError')}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};
