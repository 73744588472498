export default {
    roles: 'Rollen',
    newRole: 'Nieuwe rol',
    deleteRole: 'Verwijder',
    roleDeleteWarningTitle: 'Verwijder rol',
    roleDeleteWarningText: 'Bent u zeker dat u deze rol wilt verwijderen?',
    rolName: 'Naam rol',
    permissionsLabel: 'Permissies (Enter om permissie toe te voegen)',
    roleNameUniqueError: 'Naam van de rol moet uniek zijn',
};
