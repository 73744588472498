import { Navigate, Route, Routes as RRRoutes } from 'react-router-dom';
import { PageNotFound } from '../../pages';
import { IRouteConfig, IRouteConfigWithRedirect } from '../../../shared';
import React from 'react';
import { ProtectedRoute } from '../../../shared';
import { userRoutes } from '../../../user';
import { roleRoutes } from '../../../role';
import { providerRoutes } from '../../../provider';
import { applicationRoutes } from '../../../application';

export const ROUTES: IRouteConfig[] = [
    {
        path: '/',
        redirect: '/users',
    },
    ...userRoutes,
    ...roleRoutes,
    ...providerRoutes,
    ...applicationRoutes,
];

function isRedirect(config: IRouteConfig): config is IRouteConfigWithRedirect {
    return !!(config as IRouteConfigWithRedirect).redirect;
}

export function Routes() {
    return (
        <RRRoutes>
            {ROUTES.map((config, i) =>
                isRedirect(config) ? (
                    <Route path={config.path} key={i} element={<Navigate to={config.redirect} />} />
                ) : (
                    <Route
                        key={i}
                        path={config.path}
                        element={
                            <ProtectedRoute requiredPermission={config.requiredPermissions}>
                                <config.component />
                            </ProtectedRoute>
                        }
                    />
                ),
            )}
            <Route path="*" element={<PageNotFound />} />
        </RRRoutes>
    );
}
