export default {
    provider: 'Login configuratie',
    providers: 'Login configuraties',
    providerKey: 'Sleutel',
    providerKeyMatches: 'De sleutel kan enkel bestaan uit kleine letters en -',
    providerName: 'Naam',
    providerType: 'Type',
    providerConfig: 'Instellingen',
    newProvider: 'Nieuwe configuratie',
    providerNameUniqueError: 'De naam van de configuratie moet uniek zijn.',
    providerDeleteWarningTitle: 'Verwijder configuratie',
    providerDeleteWarningText: 'Bent u zeker dat u deze configuratie wil verwijderen?',
    invalidJSONFormat: 'Ongeldig json formaat',
};
