import { Tab, Tabs } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { PermissionKeys, useHasPermission } from '../../../shared';

export const NavBar: FC = () => {
    const { t } = useTranslation();
    const { hasPermission, isLoading } = useHasPermission();
    const location = useLocation();
    const selectedTab = useMemo(() => location.pathname.split('/')[1] || 'users', [location]);

    const tabs: { key: string; label: string; permission?: PermissionKeys }[] = [
        { key: 'users', label: t('users'), permission: PermissionKeys.USERS_READ },
        { key: 'roles', label: t('roles'), permission: PermissionKeys.ROLES_READ },
        { key: 'providers', label: t('providers'), permission: PermissionKeys.PROVIDERS_READ },
        { key: 'applications', label: t('applications'), permission: PermissionKeys.APPLICATIONS_READ },
    ];

    return !isLoading ? (
        <Tabs indicatorColor="primary" textColor="primary" value={selectedTab} sx={{ flexGrow: 1 }}>
            {tabs.map((tab) =>
                !tab.permission || hasPermission(tab.permission) ? (
                    <Tab
                        key={tab.key}
                        sx={{ minHeight: 55 }}
                        label={tab.label}
                        value={tab.key}
                        component={Link}
                        to={`/${tab.key}`}
                    />
                ) : null,
            )}
        </Tabs>
    ) : null;
};
