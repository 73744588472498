import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { array, object, string } from 'yup';
import {
    ControlledAutocomplete,
    ControlledInput,
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    IParams,
    IRoleForm,
    isAxiosError,
    Page,
    RemoveModal,
    rolesHook,
    StatusCode,
} from '../../../shared';

export const RoleEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: role, isLoading } = rolesHook.useItem(id);
    const { mutateAsync: deleteRole } = rolesHook.useDelete();

    const onDelete = useCallback(async () => {
        await deleteRole(id);
        navigate('/roles');
    }, [deleteRole, navigate, id]);

    const schema = object().shape({
        key: string().required(t('requiredField')),
        permissions: array().of(string()).required().min(1, t('requiredField')),
    });

    const form = useForm<IRoleForm>({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (role) {
            form.reset(role);
        }
    }, [role, form.reset]);

    const { mutateAsync: saveRole } = rolesHook.useSave();

    const onSubmit = async (item: IRoleForm) => {
        try {
            await saveRole({ item, id });
            navigate('/roles');
        } catch (e) {
            if (isAxiosError(e)) {
                if ((e as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                    form.setError('key', {
                        type: 'custom',
                        message: t('roleNameUniqueError'),
                    });
                }
            } else {
                throw e;
            }
        }
    };

    const actions = [
        <Button onClick={() => navigate('/roles')} color="secondary">
            {t('cancel')}
        </Button>,
        <>
            {id && (
                <RemoveModal
                    handleDelete={onDelete}
                    button={
                        <Button color="primary" variant="outlined" data-testid="deleteRole">
                            {t('delete')}
                        </Button>
                    }
                    title={t('roleDeleteWarningTitle')}
                    text={t('roleDeleteWarningText')}
                />
            )}
        </>,
        <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={form.handleSubmit(onSubmit, (err) => console.log(err))}
        >
            {t('save')}
        </Button>,
    ];

    return (
        <Page title={id ? `${role?.key || ''}` : t('newRole')} actions={actions} loading={isLoading}>
            <FormProvider {...form}>
                <FormCard
                    actions={actions.slice().reverse()}
                    handleSubmit={form.handleSubmit(onSubmit, (err) => console.log(err))}
                >
                    <FormCardSectionComponent title={t('data')}>
                        <FormGrid
                            direction="column"
                            items={[
                                <ControlledInput name="key" label={t('rolName')} required />,
                                <ControlledAutocomplete
                                    name="permissions"
                                    label={t('permissionsLabel')}
                                    defaultValue={[]}
                                    options={[]}
                                    multiple
                                    freeSolo
                                />,
                            ]}
                        />
                    </FormCardSectionComponent>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
