import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { ReactNode } from 'react';

interface Props {
    children?: ReactNode;
    actions?: React.ReactNode;
    footer?: React.ReactNode;
    icon?: React.ReactNode;
    title?: React.ReactNode;
    hasTable?: boolean;
    sx?: SxProps<Theme>;
    onClick?: () => void;
}

const cardStyle: SxProps<Theme> = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

export const Widget: React.FC<Props> = ({ actions, footer, icon, title, hasTable, sx = {}, children, onClick }) => {
    return (
        <Card variant="outlined" sx={{ ...sx, ...cardStyle } as SxProps<Theme>} onClick={onClick}>
            {title && (
                <CardHeader
                    title={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ marginRight: 1, lineHeight: 0 }}>{icon}</Box>
                                <Box>{title}</Box>
                            </Box>
                            <Box>{actions}</Box>
                        </Box>
                    }
                    sx={{
                        borderBottom: 1,
                        borderColor: 'grey.300',
                        paddingBottom: 1,
                        paddingTop: 1,
                        minHeight: 60,
                        flexGrow: 0,
                    }}
                />
            )}
            <CardContent
                sx={
                    hasTable
                        ? {
                              padding: 0,
                              flexGrow: 1,
                              '&:last-child': { paddingBottom: 0 },
                              '& .MuiDataGrid-root': {
                                  borderRadius: 0,
                                  border: 0,
                              },
                              '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
                                  borderBottom: 0,
                              },
                              '& .MuiDataGrid-footerContainer': {
                                  borderTop: 1,
                                  borderColor: 'grey.300',
                              },
                          }
                        : {
                              flexGrow: 1,
                          }
                }
            >
                {children}
            </CardContent>
            {footer && <CardContent sx={{ bgcolor: 'background.default' }}>{footer}</CardContent>}
        </Card>
    );
};
