export enum QueryKeys {
    USERS = 'users',
    USER = 'user',
    ROLE = 'role',
    ROLES = 'roles',
    PROVIDER = 'provider',
    PROVIDERS = 'providers',
    APPLICATION = 'application',
    APPLICATIONS = 'applications',
    USER_ROLES = 'user-roles',
    CONTEXTS = 'contexts',
    AUTH_USER = 'auth-user',
    AUTH_PROVIDERS = 'auth-provider',
}
