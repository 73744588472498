export default {
    application: 'Applicatie',
    applications: 'Applicaties',
    newApplication: 'Nieuwe applicatie',
    applicationName: 'Naam applicatie',
    applicationApiKey: 'Api key',
    applicationDeleteWarningTitle: 'Verwijder applicatie',
    applicationDeleteWarningText: 'Bent u zeker dat u deze applicatie wilt verwijderen?',
    applicationNameAndApiKeyUniqueError: 'Applicatienaam en apikey moeten uniek zijn',
    generateUUID: 'Genereer uuid',
};
