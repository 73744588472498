import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

export const AppTitle: FC = () => {
    return (
        <Box component="div" sx={{ display: { xs: 'none', xl: 'block' } }}>
            <Typography
                component="div"
                sx={{
                    alignSelf: 'stretch',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 14,
                    width: 216,
                    boxSizing: 'border-box',
                    textTransform: 'uppercase',
                    borderRight: (theme) => `solid 1px ${theme.palette.grey['300']}`,
                }}
            >
                <Box sx={{ marginRight: '12px', height: 40 }}>
                    <img src={window.carity.environment.theme.logo} style={{ height: '100%' }} alt="Carity" />
                </Box>
                Shield
                <strong>admin</strong>
            </Typography>
        </Box>
    );
};
