import { IProvider, IProviderForm, ISaveProvider } from '../../shared';

export const providerToFormMapper = (item: IProvider): IProviderForm => {
    return {
        ...item,
        config: JSON.stringify(item.config),
    };
};
export const providerFromFormMapper = (item: IProviderForm): ISaveProvider => {
    return {
        ...item,
    };
};
