import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AuthListQueryParam, ListModelItems, QueryKeys } from '../../shared';
import { usersClient } from '../clients';
import { ISaveUser, IUser, IUserRoles, IUserRolesSave } from '../models';

class UsersHook {
    public useGetUsers = (params: AuthListQueryParam) => {
        const { t } = useTranslation();

        return useQuery<ListModelItems<IUser>, AxiosError>([QueryKeys.USERS, params], () =>
            usersClient.getUsers(params, t('getUsersError')),
        );
    };

    public useGetUser = (id: string) => {
        const { t } = useTranslation();
        return useQuery<IUser, AxiosError>([QueryKeys.USER, id], () => usersClient.getUser(id, t('getUserError')), {
            enabled: !!id,
        });
    };

    public useSaveUser = () => {
        const queryClient = useQueryClient();
        const { t } = useTranslation();

        return useMutation<IUser, AxiosError, ISaveUser>(
            (data) =>
                usersClient.saveUser(data, {
                    onCreateSuccessMessage: t('createUserSuccess'),
                    onCreateErrorMessage: t('createUserError'),
                    onUpdateSuccessMessage: t('updateUserSuccess'),
                    onUpdateErrorMessage: t('updateUserError'),
                }),
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(QueryKeys.USERS);
                },
            },
        );
    };

    public useDeleteUser = () => {
        const queryClient = useQueryClient();
        const { t } = useTranslation();
        return useMutation<void, AxiosError, string>(
            (id) => usersClient.deleteUser(id, t('deleteUserSuccess'), t('deleteUserError')),
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(QueryKeys.USERS);
                },
            },
        );
    };

    public useGetUserRoles = (userId = '', params: AuthListQueryParam = {}) => {
        const { t } = useTranslation();

        return useQuery<ListModelItems<IUserRoles>, AxiosError>(
            [QueryKeys.USER_ROLES, userId, params],
            () => usersClient.getUserRoles(userId, params, t('getUserRolesError')),
            {
                enabled: !!userId,
            },
        );
    };

    public useSaveUserRoles = () => {
        const queryClient = useQueryClient();
        const { t } = useTranslation();

        return useMutation<IUserRolesSave, AxiosError, IUserRolesSave>(
            (data) => usersClient.saveUserRoles(data, t('updateUserRolesSuccess'), t('updateUserRolesError')),
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(QueryKeys.USER_ROLES);
                },
            },
        );
    };

    public useDeleteUserRoles = () => {
        const queryClient = useQueryClient();
        const { t } = useTranslation();

        return useMutation<void, AxiosError, string>(
            (id) => usersClient.deleteUserRoles(id, t('deleteUserRolesSuccess'), t('deleteUserRolesError')),
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries([QueryKeys.USER_ROLES]);
                },
            },
        );
    };
}

export const usersHook = new UsersHook();
