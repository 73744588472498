import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Page, RemoveModal, Search, TableComponent, useQueryParams } from '../../../shared';
import { usersHook } from '../../hooks';

export const UsersPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { page, setPage, pageSize, setPageSize, sort, setSort, search, setSearch, debouncedSearch } = useQueryParams({
        defaultSort: [{ field: 'lastName, firstName', sort: 'asc' }],
    });

    const { data: users, isLoading } = usersHook.useGetUsers({
        page: page,
        limit: pageSize,
        sort: sort[0].field
            .split(', ')
            .map((sortBy) => `${sortBy}:${sort[0].sort?.toUpperCase()}`)
            .join(','),
        search: debouncedSearch,
    });
    const { mutateAsync: deleteUser } = usersHook.useDeleteUser();

    const columns: GridColDef[] = [
        {
            field: 'lastName, firstName',
            headerName: t('name'),
            minWidth: 200,
            flex: 1,
            renderCell: (params) => (
                <>
                    {params.row.lastName} {params.row.firstName}
                </>
            ),
        },
        {
            field: 'email',
            headerName: t('email'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('status'),
            minWidth: 50,
            flex: 0.5,
            renderCell: (params) => t(params.row.status),
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton component={Link} to={`/users/${params.row.id}`} size="large">
                        <EditIcon />
                    </IconButton>
                    <RemoveModal
                        handleDelete={() => deleteUser(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large">
                                <DeleteOutlineIcon />
                            </IconButton>
                        }
                        title={t('userDeleteWarningTitle')}
                        text={
                            <>
                                {t('userDeleteWarningText')} <br />
                                <b>
                                    {params.row.lastName} {params.row.firstName}
                                </b>
                            </>
                        }
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('users')}
            actions={[
                <Search search={search} onSearch={setSearch} />,
                <Button component={Link} to="/users/new" color="primary" variant="contained" data-testid="newUser">
                    {t('newUser')}
                </Button>,
            ]}
        >
            <TableComponent
                rows={users?.items || []}
                columns={columns}
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={users?.meta?.totalItems || 0}
                onPageChange={(newPage) => setPage(newPage + 1)}
                loading={isLoading}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={[{ field: 'lastName, firstName', sort: 'asc' }]}
                onRowClick={(row) => navigate(`/users/${row.id}`)}
            />
        </Page>
    );
};
