import { FC } from 'react';
import { Page, RemoveModal, rolesHook, TableComponent, useQueryParams } from '../../../shared';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export const RolesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'key', sort: 'asc' }],
    });
    const { data: roles, isLoading } = rolesHook.useList({
        page: page,
        limit: pageSize,
        sort: sort[0].field
            .split(', ')
            .map((sortBy) => `${sortBy}:${sort[0].sort?.toUpperCase()}`)
            .join(','),
    });

    const { mutateAsync: deleteRole } = rolesHook.useDelete();

    const columns: GridColDef[] = [
        {
            field: 'key',
            headerName: t('name'),
            minWidth: 50,
            flex: 1,
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <>
                    <IconButton component={Link} to={`/roles/${params.row.id}`} size="large">
                        <EditIcon />
                    </IconButton>
                    <RemoveModal
                        handleDelete={() => deleteRole(params.row.id)}
                        button={
                            <IconButton aria-label="delete" size="large">
                                <DeleteOutlineIcon />
                            </IconButton>
                        }
                        title={t('roleDeleteWarningTitle')}
                        text={
                            <>
                                {t('roleDeleteWarningText')} <br />
                                <b>
                                    {params.row.lastName} {params.row.firstName}
                                </b>
                            </>
                        }
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('roles')}
            actions={[
                <Button component={Link} to="/roles/new" color="primary" variant="contained">
                    {t('newRole')}
                </Button>,
            ]}
        >
            <TableComponent
                rows={roles?.items || []}
                columns={columns}
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={roles?.meta?.totalItems || 0}
                onPageChange={(newPage) => setPage(newPage + 1)}
                loading={isLoading}
                sortModel={sort}
                onSortModelChange={setSort}
                defaultSortModel={[{ field: 'key', sort: 'asc' }]}
                onRowClick={(row) => navigate(`/roles/${row.id}`)}
            />
        </Page>
    );
};
