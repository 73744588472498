import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledInput,
    ControlledSelect,
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    IParams,
    isAxiosError,
    Page,
    RemoveModal,
    StatusCode,
} from '../../../shared';
import { applicationHook } from '../../hooks';
import { array, object, string } from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { IApplicationForm } from '../../models';
import { AxiosError } from 'axios';
import { Button, MenuItem } from '@mui/material';
import { applicationFromFormMapper, applicationToFormMapper } from '../../mappers';
import { providerHook } from '../../../provider/hooks';
import { v4 as uuidv4 } from 'uuid';

export const ApplicationEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: application, isLoading } = applicationHook.useItem(id);
    const { data: providers } = providerHook.useList({ page: 1, limit: 1000 });
    const { mutateAsync: saveApplication } = applicationHook.useSave();
    const { mutateAsync: deleteApplication } = applicationHook.useDelete();

    const schema = object().shape({
        key: string().required(t('requiredField')),
        apiKey: string().nullable(),
        providers: array(),
    });

    const form = useForm<IApplicationForm>({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (application) {
            form.reset(applicationToFormMapper(application));
        }
    }, [application, form.reset]);

    const onSubmit = async (item: IApplicationForm) => {
        try {
            await saveApplication({ item: applicationFromFormMapper(item), id });
            navigate('/applications');
        } catch (e) {
            if (isAxiosError(e)) {
                if ((e as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                    form.setError('key', {
                        type: 'custom',
                        message: t('applicationNameAndApiKeyUniqueError'),
                    });
                    form.setError('apiKey', {
                        type: 'custom',
                        message: t('applicationNameAndApiKeyUniqueError'),
                    });
                }
            } else {
                throw e;
            }
        }
    };

    const onDelete = useCallback(async () => {
        await deleteApplication(id);
        navigate('/applications');
    }, [deleteApplication, navigate, id]);

    const actions = [
        <Button onClick={() => navigate('/applications')} color="secondary">
            {t('cancel')}
        </Button>,
        <>
            {id && (
                <RemoveModal
                    handleDelete={onDelete}
                    button={
                        <Button color="primary" variant="outlined">
                            {t('delete')}
                        </Button>
                    }
                    title={t('applicationDeleteWarningTitle')}
                    text={t('applicationDeleteWarningText')}
                />
            )}
        </>,
        <Button type="submit" variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
            {t('save')}
        </Button>,
    ];

    return (
        <Page title={id ? `${application?.key || ''}` : t('newApplication')} actions={actions} loading={isLoading}>
            <FormProvider {...form}>
                <FormCard actions={actions.slice().reverse()} handleSubmit={form.handleSubmit(onSubmit)}>
                    <FormCardSectionComponent title={t('data')}>
                        <FormGrid direction="column">
                            <ControlledInput name="key" label={t('applicationName')} required />
                            <FormGrid sx={{ alignItems: 'center' }}>
                                <ControlledInput name="apiKey" label={t('applicationApiKey')} sx={{ minWidth: 400 }} />
                                <Button variant="outlined" onClick={() => form.setValue('apiKey', uuidv4())}>
                                    {t('generateUUID')}
                                </Button>
                            </FormGrid>
                            <ControlledSelect name="providers" label={t('providers')} multiple defaultValue={[]}>
                                {providers?.items?.map((provider) => (
                                    <MenuItem key={provider.id} value={provider.id}>
                                        {provider.key}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </FormGrid>
                    </FormCardSectionComponent>
                </FormCard>
            </FormProvider>
        </Page>
    );
};
