import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CarityTheme, Main, Statusbar } from './main';
import './translations/i18n';

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export function App() {
    return (
        <CarityTheme>
            <QueryClientProvider client={queryClient}>
                <Main />
                <Statusbar />
            </QueryClientProvider>
        </CarityTheme>
    );
}
