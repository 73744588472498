import { IRole, ISaveRole, QueryKeys } from '../index';
import { rolesClient } from '../clients';
import { BaseHook } from './base.hook';

class RolesHook extends BaseHook<IRole, ISaveRole> {
    public constructor() {
        super(rolesClient, QueryKeys.ROLES, QueryKeys.ROLE);
    }
}
export const rolesHook = new RolesHook();
