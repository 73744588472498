import { Login, useGetCurrentUser } from '../../../auth';
import { DrawerContextProvider, Loading, PermissionsContext } from '../../../shared';
import { usersHook } from '../../../user/hooks';
import { Header } from '../header/header.component';
import { Routes } from '../routes/routes.component';

export function Main() {
    const { data, isLoading } = useGetCurrentUser();

    const { data: roles } = usersHook.useGetUserRoles(data?.data?.id);

    if (isLoading) {
        return <Loading fullscreen />;
    }

    if (!data?.success) {
        return <Login />;
    }

    return (
        <PermissionsContext.Provider value={{ permissions: roles?.items }}>
            <DrawerContextProvider>
                <Header />
                <Routes />
            </DrawerContextProvider>
        </PermissionsContext.Provider>
    );
}
