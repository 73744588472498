import { IApplication, IApplicationForm, ISaveApplication } from '../models';

export const applicationToFormMapper = (item: IApplication): IApplicationForm => {
    return {
        ...item,
        apiKey: item.apiKey || '',
        providers: item?.providers?.map(({ id }) => id) || [],
    };
};

export const applicationFromFormMapper = (item: IApplicationForm): ISaveApplication => {
    return {
        ...item,
        providers: item.providers || [],
    };
};
