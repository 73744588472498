import { useCallback, useContext, useMemo } from 'react';
import { PermissionsContext } from '../contexts';
import { PermissionKeys } from '../enums';

interface UseHasPermissionResult {
    hasPermission: (requiredPermission: PermissionKeys | PermissionKeys[], skipOrganisationCheck?: boolean) => boolean;
    isLoading: boolean;
}

export const useHasPermission = (): UseHasPermissionResult => {
    const { permissions: userRoles } = useContext(PermissionsContext);

    const userPermissions = useMemo(
        () => userRoles?.flatMap((role) => role.roles).flatMap((role) => role.permissions as PermissionKeys[]),
        [userRoles],
    );

    const hasPermission = useCallback(
        (requiredPermission: PermissionKeys | PermissionKeys[]): boolean =>
            permissionChecker(userPermissions || [], requiredPermission),
        [userPermissions],
    );

    return { hasPermission, isLoading: !userPermissions };
};

function permissionChecker(permissions: PermissionKeys[], requiredPermission: PermissionKeys | PermissionKeys[]) {
    if (Array.isArray(requiredPermission)) {
        return requiredPermission.every((perm) => permissions.includes(perm));
    }
    return permissions.includes(requiredPermission);
}
