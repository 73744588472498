import { IRouteConfig, PermissionKeys } from '../shared';
import { ApplicationEditPage, ApplicationPage } from './pages';

export const applicationRoutes: IRouteConfig[] = [
    { path: '/applications', component: ApplicationPage },
    {
        path: '/applications/new',
        component: ApplicationEditPage,
        requiredPermissions: PermissionKeys.APPLICATIONS_WRITE,
    },
    {
        path: '/applications/:id',
        component: ApplicationEditPage,
        requiredPermissions: PermissionKeys.APPLICATIONS_WRITE,
    },
];
