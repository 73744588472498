import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app.component';
import { IEnvironment } from './shared';

const initializeSentry = async () => {
    const { data: environment } = await axios.get<IEnvironment>(`/environment`);
    window.carity = {
        environment,
    };
    if (environment.sentryEnabled) {
        await Sentry.init({
            dsn: environment.sentryUrl,
            integrations: [new Integrations.BrowserTracing()],
            environment: environment.environment,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.1,
        });
    }
};

const root = createRoot(document.getElementById('root') as HTMLElement);

initializeSentry().finally(() =>
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>,
    ),
);
