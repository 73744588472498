import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { IProvider } from '../../models';
import { QueryKeys } from '../../../shared';

async function getProviders(onGetErrorMessage?: string): Promise<IProvider[]> {
    const { data } = await axios.get('/auth/providers', { errorMessage: onGetErrorMessage });
    return data;
}

export const useGetProviders = () => {
    const { t } = useTranslation();
    return useQuery<IProvider[], AxiosError>(
        [QueryKeys.AUTH_PROVIDERS],
        () => getProviders(t('getProvidersError')),
        {},
    );
};
