import { AuthListQueryParam, ListModelItems } from '../../shared';
import { authApi } from '../../shared/clients';
import { IContext } from '../models';

class ContextsClient {
    public async getList(params: AuthListQueryParam, onGetErrorMessage?: string): Promise<ListModelItems<IContext>> {
        const { data } = await authApi.get(`/v1/contexts`, {
            params,
            errorMessage: onGetErrorMessage,
        });

        return data;
    }
}

export const contextsClient = new ContextsClient();
