import { AxiosInstance } from 'axios';
import { AuthListQueryParam, ListModelItems } from '../models';

export abstract class ReadOnlyClient<ResponseModel, QueryModel extends AuthListQueryParam = AuthListQueryParam> {
    protected constructor(protected api: AxiosInstance, protected basePath: string) {}

    public async getList({ limit = 10, ...query }: QueryModel): Promise<ListModelItems<ResponseModel>> {
        const { data } = await this.api.get<ListModelItems<ResponseModel>>(this.basePath, {
            params: { limit, ...query },
        });
        return data;
    }

    public async getOne(id?: string): Promise<ResponseModel> {
        const { data } = await this.api.get<ResponseModel>(`${this.basePath}/${id}`);
        return data;
    }
}
