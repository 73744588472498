import { AccountCircle, Logout, Person } from '@mui/icons-material';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCurrentUser } from '../../../auth';

export const Account: FC = () => {
    const { t } = useTranslation();
    const { data } = useGetCurrentUser();
    const user = data?.data;
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const onLogOut = useCallback(() => {
        window.location.href = '/auth/logout';
    }, []);

    return (
        <>
            <IconButton size="large" edge="end" color="inherit" onClick={(e) => setAnchorEl(e.currentTarget)}>
                <AccountCircle />
            </IconButton>
            <Menu
                sx={{ mt: 5 }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(undefined)}
            >
                <MenuItem>
                    <ListItemIcon>
                        <Person />
                    </ListItemIcon>
                    {user?.firstName} {user?.lastName}
                </MenuItem>
                <MenuItem onClick={onLogOut}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    {t('logOut')}
                </MenuItem>
            </Menu>
        </>
    );
};
