export default {
    users: 'Gebruikers',
    newUser: 'Nieuwe gebruiker',
    getUsersError: 'Er is een probleem opgetreden bij het ophalen van de gebruikers.',
    status: 'Status',
    pending: 'Inactief',
    enabled: 'Actief',
    email: 'Email',
    updateUserError: 'Er is een probleem opgetreden bij het aanpassen van de gebruiker.',
    updateUserSuccess: 'Gebruiker successvol aangepast.',
    createUserError: 'Er is een probleem opgetreden bij het aanmaken van de gebruiker.',
    createUserSuccess: 'Gebruiker successvol aangemaakt.',
    emailRequired: 'Email is verplicht',
    invalidEmail: 'Geen geldig mailadres.',
    addUserRoles: 'Voeg rollen toe',
    minOneValueSelected: 'Selecteer minstens 1 item',
    updateUserRolesError: 'Er is een probleem opgetreden bij het aanpassen van de rollen van de gebruiker.',
    updateUserRolesSuccess: 'Rollen gebruiker successvol aangepast.',
    getUserRolesError: 'Er is een probleem opgetreden bij het ophalen van de rollen van de gebruiker.',
    userRoleDeleteWarningTitle: 'Verwijder rollen',
    userRoleDeleteWarningText: 'Bent u zeker dat u de rollen van de gebruiker voor deze organisatie wil verwijderen?',
    deleteUserRolesSuccess: 'Rollen gebruiker successvol verwijdert.',
    deleteUserRolesError: 'Er is een probleem opgetreden bij het verwijderen van de rollen.',
    deleteUserSuccess: 'Gebruiker successvol verwijdert.',
    deleteUserError: 'Er is een probleem opgetreden bij het verwijderen van de gebruiker.',
    userDeleteWarningText: 'Bent u zeker dat u deze gebruiker wil verwijderen?',
    userDeleteWarningTitle: 'Verwijder gebruiker',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    firstNameRequired: 'Voornaam is verplicht.',
    lastNameRequired: 'Naam is verplicht.',
    emailUniqErr: 'Email moet uniek zijn',
};
